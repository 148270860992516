<template>
  <div>
    <v-card>
      <v-card-title>{{ this.title }}</v-card-title>
      <v-card-text>
        {{ this.notes }}
      </v-card-text>
    </v-card>
    <!-- <line-chart :isDetail="true" :pointData =chartData :height=350 titleName=""  xAxisName="Date"  yAxisName="Value"></line-chart> -->
    <line-chart
      :isDetail="true"
      :series="series"
      :height="350"
      titleName=""
      dataType="datetime"
      tickAmount="dataPoints"
      xAxisName="Date"
      yAxisName="Value"
    ></line-chart>
    <v-card>
      <v-card-title>
        Data Table
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import LineChart from "@/components/chart/ApexChart/LineChart.vue";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      title: "",
      notes: "",
      search: "",
      id: "",
      source: "",
      url: "http://192.168.50.3:9012/grocery/v1/",
      headers: [
        {
          text: "Data",
          sortable: true,
          value: "date",
        },
        { text: "Value", sortable: true, value: "value" },
      ],
      data: [],
      chartData: [],
      series: [],
    };
  },
  created() {
    (this.source = this.$route.params.source),
      (this.id = this.$route.params.id),
      console.log(this.id);
    console.log(this.source);

    axios
      .get(this.url + `indexes?id=${this.id}&source=${this.source}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const souceData = res.data;

          let chartData = [];
          // filter the null value
          this.data = souceData.data.values.filter(
            (item) => item.value !== null
          );
          chartData = this.data.map((element) => {
            return { x: element.date, y: element.value };
          });
          this.series = [
            {
              name: "series1",
              data: chartData,
            },
          ];

          console.log("this.data");
          console.log(this.data);
          this.notes = souceData.data.notes;
          this.title = souceData.data.title;
        }
      })
      .catch(() => {
        console.log("Failure");
      });
  },
};
</script>

<style></style>
